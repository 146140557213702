<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <v-app>
    <v-main>
      <v-container fluid>
        <h4 class="headline">
          {{ $t('logoutPage.loading') }}
        </h4>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'Logout',
  mounted () {
    this.logoutUser(!0)
  }
}
</script>
